export const POINT_TO_POINT_PERMISSIONS = 'txt:at';
export const RENTAL_DAILY_PERMISSIONS = 'txt:cr';

export const ALLOTMENT_AND_RATE_PAGE_PERMISSIONS = 'r:inSchVcs';
export const ALLOTMENT_AND_RATE_USAGE_PAGE_PERMISSIONS =
  'aries:r:srchInvV2WthUsg';
export const ALLOTMENT_AND_RATE_QUANTITY_PERMISSIONS = 'aries:u:updtAllnRteQty';
export const ALLOTMENT_AND_RATE_PRICE_PERMISSIONS = 'aries:u:updtAllnRtePrice';

export const ALLOTMENT_DRIVERLESS_PAGE_PERMISSIONS = 'aries:s:inVhclDrvrlss';
export const ALLOTMENT_DRIVERLESS_RATE_PAGE_PERMISSIONS = 'txt:s:dvprc';
export const ALLOTMENT_DRIVERLESS_RATE_UPSERT_PERMISSIONS = 'txt:u:dvprc';
export const ALLOTMENT_DRIVERLESS_USAGE_PAGE_PERMISSIONS =
  'aries:r:srchInvDrvrlsWthUsg';

export const ADDON_RATE_PERMISSIONS = 's:addnRt2';
export const ADDON_RATE_UPSERT_PERMISSIONS = 'u:addnRt2';
export const INVENTORY_MONITORING_PAGE_PERMISSIONS = 'aries:r:srchInvMntrng';
export const DOWNLOAD_INVENTORY_MONITORING_PERMISSIONS =
  'aries:r:exprtInvMntrngCsv';
export const PARTNER_CHANGES_PAGE_PERMISSIONS = 'r:inVcChgs';
export const BOOKING_MANIFEST_PAGE_PERMISSIONS = 'r:bkVhcls';
export const BOOKING_MANIFEST_PRICINGS_PERMISSIONS = 'aries:g:bkVhclsPrcg';
export const BOOKING_MANIFEST_PARTNER_BOOKING_ID_PAGE_PERMISSIONS =
  'aries:getBID';
export const BOOKING_MANIFEST_DETAIL_PAGE_PERMISSIONS = 'r:bkDetail';
export const BOOKING_MANIFEST_SUMMARY_PAGE_PERMISSIONS = 'txt:s:bkRtlDtl';
export const BOOKING_MANIFEST_UPDATE_RENTAL_DETAIL_PAGE_PERMISSIONS =
  'txt:u:bkRtlDtl';
export const BOOKING_MANIFEST_VIEW_RENTAL_DETAIL_PAGE_PERMISSIONS =
  'txt:g:bkRtlDtl';
export const BOOKING_MANIFEST_POST_ISSUANCE_STATUS_PERMISSIONS = 'txt:u:issSts';
export const BOOKING_MANIFEST_VOUCHER_DOWNLOAD_PERMISSIONS = 'txt:g:dwlVcrBk';
export const BOOKING_MANIFEST_LIST_DOWNLOAD_PERMISSIONS =
  'aries:r:bkVhclsExport';
export const BOOKING_REPORT_PAGE_PERMISSIONS = 'txt:s:bkRprt';
export const BOOKING_REPORT_BASIC_PRICE_PERMISSIONS = 'aries:getBaPe';
export const DOWNLOAD_BOOKING_REPORT_PERMISSIONS = 'txt:e:bkRprt';
export const DOWNLOAD_CAR_RENTAL_BOOKING_REPORT_PERMISSIONS = 'txt:e:bkCrRprt';

export const SUPPLIER_PAGE_PERMISSIONS = 'r:srchSpGrps';
export const SUPPLIER_ZONE_RESOLVER_PAGE_PERMISSIONS = 'txt:s:znRslvr';
export const SUPPLIER_DETAIL_PAGE_PERMISSIONS = 'r:spGrp';
export const SUPPLIER_DETAIL_DOCUMENT_PAGE_PERMISSIONS = 'txt:s:docRule';
export const SUPPLIER_DETAIL_DOCUMENT_UPDATE_PERMISSIONS = 'txt:u:docRule';
export const SUPPLIER_LEAD_TIME_PAGE_PERMISSIONS = 'aries:r:ldTmCnfgrtn';
export const SUPPLIER_CAR_RENTAL_LEAD_TIME_PAGE_PERMISSIONS =
  'txt:s:ldTmCnfgrtn';

export const SUPPLIER_CITY_PAGE_PERMISSIONS = 'r:srchSpRoutes';
export const SUPPLIER_CITY_DETAIL_PAGE_PERMISSIONS = 'r:spRoute';
export const DRIVER_PAGE_PERMISSIONS = 'txt:s:drv';
export const DRIVER_APP_STATUS_PERMISSIONS = 'txt:s:drvStatus';
export const DRIVER_DETAIL_PAGE_PERMISSIONS = 'txt:g:drv';
export const SUPPLIER_CITY_LEAD_TIME_PAGE_PERMISSIONS = 'aries:r:ldTmCnfgrtn';
export const SUPPLIER_CITY_ROUTE_PAGE_PERMISSIONS = 'aries:r:srchServedZone';
export const SUPPLIER_CITY_PICKUP_PROCEDURE_PAGE_PERMISSIONS =
  'aries:r:srchPickupProcedure';
export const SUPPLIER_CITY_TERM_PAGE_PERMISSIONS = 'aries:r:srchGeneralTnc';

export const LEAD_TIME_PAGE_PERMISSIONS = 'aries:r:ldTmCnfgrtn';

export const PRODUCT_PAGE_PERMISSIONS = 'r:srchPdct';
export const PRODUCT_DETAIL_PAGE_PERMISSIONS = 'r:pdct';
export const PRODUCT_SERVED_ZONE_PAGE_PERMISSIONS =
  'aries:r:srchPrdctServedZone';
export const PRODUCT_CONFIGURATION_PAGE_PERMISSIONS =
  'aries:r:srchPrdctSllngPlcy';

export const TURN_AROUND_TIME_PAGE_PERMISSIONS = 'txt:g:taTmCnfgrtn';

export const KEY_INFORMATION_PAGE_PERMISSIONS = 'txt:s:prdctUsp';
export const REFUND_RESCHEDULE_PAGE_PERMISSIONS = 'txt:g:rfndRschdl';
export const REFUND_RESCHEDULE_PAGE_UPSERT_PERMISSIONS = 'txt:u:rfndRschdl';

export const ADDON_INFORMATION_PAGE_PERMISSION = 'txt:r:addonInformation';
export const ADDON_INFORMATION_PAGE_UPSERT_PERMISSION = 'txt:u:addonInformation';

export const CITY_PAGE_PERMISSIONS = 'r:srchRoutes';
export const CITY_DETAIL_PAGE_PERMISSIONS = 'r:route';
export const CITY_SEASON_DETAIL_PAGE_PERMISSIONS = 'r:routeSsn';

export const USER_PAGE_PERMISSIONS = 'r:srchUsers';
export const USER_DETAIL_PAGE_PERMISSIONS = 'r:user';
export const USER_JOB_QUEUE_PERMISSIONS = 'aries:r:getUsrJob';
export const USER_UPDATE_COUNTRY_PERMISSIONS = 'txt:u:ctry';

export const CAR_TYPE_PAGE_PERMISSIONS = 'r:srchVhcls';
export const CAR_TYPE_DETAIL_PAGE_PERMISSIONS = 'r:vhcl';

export const SEASON_PAGE_PERMISSIONS = 'r:srchSeason';
export const SEASON_DETAIL_PAGE_PERMISSIONS = 'r:season';
export const VEHICLE_LICENSE_PLATE_PAGE_PERMISSIONS =
  'aries:r:srchLicensePlate';
export const FARE_PAGE_PERMISSIONS = 'aries:r:srchVhclPrdctRateCnfg';

export const PREBUY_PAGE_PERMISSIONS = 'txt:s:prb';
export const PREBUY_DETAIL_PAGE_PERMISSIONS = 'txt:g:prb';
export const PREBUY_APPROVAL_PERMISSIONS = 'txt:u:prbapp';
export const PREBUY_SHOW_PRICE_PERMISSIONS = 'txt:s:prbPrc';

export const FAILED_PAYMENT_PAGE_PERMISSIONS = null;

export const RESEND_ISSUANCE_EMAIL_PERMISSIONS = 'c:rsndIssnceMail';
export const RESEND_ISSUANCE_SMS_PERMISSIONS = 'c:rsndIssnceSms';
export const RESEND_CANCELLATION_EMAIL_PERMISSIONS = 'c:rsndCnclMail';
export const RESEND_CANCELLATION_SMS_PERMISSIONS = 'c:rsndCnclSms';

// constant for no permission
export const NO_PERMISSION = 'no-permission';

export const DRIVER_REWARD_PAGE_PERMISSIONS = 'aries:r:srchDrvrPnts'; // TODO: need to change permission

export const DRIVER_FEEDBACK_PAGE_PERMISSIONS = 'aries:r:srchDrvrFdbck';

export const TOLL_PAGE_PERMISSIONS = 'txt:s:mtrTl';
export const TOLL_UPSERT_PAGE_PERMISSIONS = 'txt:u:mtrTl';

export const PARKING_FEE_PERMISSIONS = 'txt:s:mtrPrk';

export const USP_SUPPLIER_PERMISSIONS = 'txt:s:splrSellPt';
export const USP_SUPPLIER_UPSERT_PERMISSIONS = 'txt:u:splrSellPt';

export const USP_PRODUCT_PERMISSIONS = 'txt:s:prdctUsp';
export const USP_PRODUCT_UPSERT_PERMISSIONS = 'txt:u:prdctUsp';

export const POOL_PERMISSIONS = 'txt:s:spPoolSmry';
export const POOL_DETAIL_GET_PERMISSIONS = 'txt:r:spPool';
export const POOL_DETAIL_UPSERT_PERMISSIONS = 'txt:u:spPool';
export const POOL_DETAIL_CHANGE_STATUS_PERMISSIONS = 'txt:u:spPoolSts';

export const CARPOOL_PAGE_PERMISSIONS = 'txt:r:carpoolAdminP2P';

export const INSTANT_BOOKING_DASHBOARD_PAGE_PERMISSIONS = 'txt:p:instBook';
export const INSTANT_BOOKING_DASHBOARD_DRIVER_QUEUE_PERMISSIONS = 'txt:s:dvrq';
export const INSTANT_BOOKING_DASHBOARD_CUSTOMER_HELP_PERMISSIONS =
  'txt:g:cstmrHelp';
export const INSTANT_BOOKING_DASHBOARD_CUSTOMER_HELP_CHANGE_DRIVER_PERMISSIONS =
  'txt:u:chgDrv';

// pps redeem booking
export const PPS_READ_BOOKING_PERMISSIONS = 'txt:r:ppsBook';
export const PPS_REDEEM_BOOKING_PERMISSIONS = 'txt:u:ppsRdmBook';

export const AUTHORIZED_AIRPORTS_PERMISSIONS = 'txt:u:athrizedAirport';

export const PICKUP_DROPOFF_FEE_PERMISSIONS = 'r:pckpDrpffF';

export const DRIVER_ACCOUNT_PERMISSIONS = 'aries:u:driveruser';

export const DRIVER_RESET_PASSWORD_PERMISSIONS = 'txt:u:rstdrvpwd';

export const ROUTE_MAINTENANCE_PERMISSIONS = 'txt:u:route';
export const ROUTE_ZONAL_MAINTENANCE_PERMISSIONS = 'txt:s:RtZnl';
export const ROUTE_ZONAL_POINT_MAINTENANCE_PERMISSIONS = 'txt:s:rtZnlPnt';

export const DOCUMENT_PAGE_PERMISSIONS = 'r:srchSpGrps';

export const DRIVERLESS_RATE_SURCHARGE_PERMISSIONS = 's:drvrlssSrchrg';
export const DRIVERLESS_RATE_PROMO_PERMISSIONS = 's:drvrlssSrchrg'; // TODO: change permission

export const HEALTH_MONITORING_PERMISSIONS = 'txt:g:issCount';

export const OPERATIONAL_HOUR_PERMISSIONS = 'txt:s:drvlsOprtnlHr';

export const NOTIFICATION_BUTTON_PERMISSIONS = 'txt:s:notification';

export const SURCHARGE_BY_SEARCH_TIME_PAGE_PERMISSIONS = 'txt:s:srcToDprt';

export const CAMPAIGN_AT_PERMISSIONS = 'txt:s:ATcmpgn';
export const CAMPAIGN_CR_PERMISSIONS = 'txt:s:CRcmpgn';

export const FARE_PROMO_PERMISSIONS = 'aries:r:promo';
export const PARTNER_CHANGES_AT_PERMISSION = 'aries:r:activityLog';

export const CONFIG_V2_PERMISSIONS = "aries:r:configV2";